import { FC, useMemo } from 'react';
import AddressLocation from '../../../../models/AddressLocation';
import ActionPreview from '../../ActionPreview';
import { ActionPreviewBaseProps } from '../ActionBaseProps';

const AddressPreview: FC<ActionPreviewBaseProps<AddressLocation>> = (props) => {
  const { answerOnly, response, data } = props;
  const { question, previewQuestion, previewDescription } = data;

  const title = useMemo(() => previewQuestion || question, [previewQuestion, question]);
  const answerText = useMemo(() => (response && response?.address) || '-', [response]);

  if (answerOnly) {
    return <>{answerText}</>;
  }

  return <ActionPreview data-cy="address-preview" question={title} description={previewDescription} answer={answerText} />;
};

export default AddressPreview;
